<template>
  <div>
    <div class="xskcodeinput">
      <div
        class="box"
        :style="{
          width: width + 'px',
          height: height + 'px',
          backgroundColor: backgroundColor,
        }"
        @click="focus = true"
        v-for="(item, index) in length"
        :key="index"
      >
        <div
          v-if="payPassword.length > index"
          :style="{ bold: bold ? 'bold' : 'normal' }"
          style="line-height: 78px"
        >
          {{ showVal ? payPassword[index] : "*" }}
        </div>
        <div
          class="line"
          v-if="payPassword.length == index"
          style="
            font-weight: normal;
            font-size: 30px;
            height: 59px;
            line-height: 59px;
          "
        >
          |
        </div>
      </div>

      <input
        class="input"
        type="number"
        :focus="focus"
        v-model="payPassword"
        maxlength="6"
        @focus="focus = true"
        @blur="focus = false"
        @input="userinput"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "xskCodeInput",
  data() {
    return {
      focus: false,
      payPassword: "",
    };
  },
  props: {
    width: {
      type: [Number, String],
      default: 84,
    },
    height: {
      type: [Number, String],
      default: 84,
    },
    backgroundColor: {
      type: String,
      default: "#EDEDED",
    },
    bold: {
      type: Boolean,
      default: true,
    },
    showVal: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: 6,
    },
  },
  methods: {
    userinput(e) {
      this.$emit("update:value", this.payPassword);
      //   if (e.detail.value.length == this.length) {
      //     this.focus = false;
      //     this.$emit("confirm");
      //   }
    },
  },
};
</script>

<style lang="scss" scoped>
.xskcodeinput {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  .box {
    margin-right: 12px;
    text-align: center;
    font-size: 38px;
    line-height: 59px;
    border: 1px solid #263a97;
    color: #3a3a3c;
    font-size: 48px;
    font-weight: 700;
    border-radius: 16px;
    &:last-child {
      margin-right: 0;
    }
    .line {
      opacity: 0;
      animation-name: donghua;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-duration: 0.5s;
      animation-direction: alternate;
    }
    @keyframes donghua {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  .input {
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    opacity: 0;
  }
}
</style>

